.reports-modal {
    padding-top: 0;
    max-height: 70vh;
}

.reports-modal .time-range {
    margin-top: 0;
    font-size: var(--font-xs);
    color: var(--color-gray-on-dark-background);
}

.reports-modal > section.tabs {
    z-index: var(--zIndex-modal);
    margin-top: var(--margin-m);
    position: sticky;
    top: 0;
    background-color: var(--color-background);
    padding: var(--padding-s) 0;
    width: 100%;
}

.reports-modal > section:first-child {
    margin-bottom: var(--margin-s);
}

.reports-modal > section > button {
    padding: var(--padding-xxs);
    width: auto;
    height: auto;
    border-radius: 9999px;
    background-color: var(--color-background);
    border: var(--border-default);
    text-align: center;
}

.reports-modal > section > button.active {
    background-color: var(--color-primary);
}

.reports-modal > section > button.active > h3 {
    color: var(--color-background);
}

.report-item {
    display: flex;
    flex-direction: column;
    margin-top: var(--margin-s);
    border-bottom: var(--border-weak);
}

.list-modal > .report-item:last-child,
.summary .clustered-report-item.align-child-on-line .report-item {
    border-bottom: none;
}

.summary .clustered-report-item.align-child-on-line {
    border-bottom: var(--border-weak);
}

.list-modal > .report-item p {
    color: var(--color-gray-on-dark-background);
}

.list-modal > div span {
    color: var(--color-primary);
}

.report-item > div {
    justify-content: left;
}

.report-item > div:first-child h4 {
    margin: 0 var(--margin-s);
}

.report-item > div:first-child > p:last-child {
    margin-left: auto;
}

.clustered-report-item {
    position: relative;
}

.clustered-report-item button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    min-width: 20px;
    width: 30px;
    height: 30px;
    padding: 0;
    outline: none;
}

.clustered-report-item button::before {
    content: '';
    position: absolute;
    margin-top: 8px;
    width: 10px;
    height: 10px;
    border-left: 2px solid var(--color-primary);
    border-top: 2px solid var(--color-primary);
    transform: translate(-50%, -50%) rotate(45deg);
    transition: transform var(--transition-default) ease;
    border-radius: 1px;
}

.clustered-report-item button.expanded::before {
    transform: translate(-50%, -75%) rotate(225deg);
}

.clustered-report-item-list {
    overflow: hidden;
    max-height: 0;
}

.clustered-report-item-list.expanded {
    max-height: 2000px;
    transition: max-height var(--transition-long) ease-in;
}
.clustered-report-item-list .inner-list {
    padding-top: var(--padding-xs);
}

.clustered-report-item-list .clustered-report-item .report-item {
    margin-top: 0;
    margin-bottom: var(--margin-xs);
}

.title-report-item {
    width: 92%;
}

.clustered-report-item-list {
    width: 95%;
    margin-left: auto;
}

section.summary .risk h2 {
    margin-top: var(--margin-s);
    margin-bottom: var(--margin-s);
}

.risk-grid {
    display: flex;
    flex-direction: column;
}

.risk-grid-item {
    width: auto;
    justify-content: left;
    margin-top: var(--margin-m);
    overflow-x: auto;
    display: flex;
    padding-bottom: var(--padding-xs);
}

.risk-grid-item > div {
    flex-shrink: 0;
    margin-right: var(--margin-m);
}

.risk-grid-item > div:last-child {
    margin-right: 0;
}

.risk-grid-item:first-child {
    margin-top: 0;
}

.risk-line {
    text-align: center;
    width: 50px;
    height: 65px;
    margin-right: var(--margin-m);
    border-radius: var(--borderRadius-small);
    display: flex;
    flex-direction: column;
}

.risk-line.risk-level-0 {
    background-color: #7cc7ab;
}

.risk-line.risk-level-1 {
    background-color: #ffe498;
}

.risk-line.risk-level-2 {
    background-color: #f16c63;
}

.risk-line.risk-level-3 {
    background-color: #f16c63;
}

.risk-line .line {
    width: 100%;
    margin-top: 0;
    text-align: center;
    padding: 0;
}

.risk-grid .risk-line img {
    filter: none;
    margin: auto 0;
    align-self: center;
    height: 20px;
    width: 20px;
}

.lines {
    min-height: 330px;
}

.risk-grid {
    min-height: 69px;
}

.summary .lines > div:last-of-type .clustered-report-item {
    border-bottom: none;
}

.custom-tooltip h4,
.custom-tooltip p {
    margin: 0;
}

.list-modal svg {
    filter: none;
}

.recharts-tooltip-cursor {
    fill: rgba(121, 120, 120, 0.1) !important;
}
